import React, { useState } from 'react';
import './CardPratos.css';
import { toCurrency } from '../../helpers/stringFormater';

const CardPratos = ({ prato }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(true);
  };

  return (
    <li>
      <div className="noImage">
        <div>
          <div className="espacamento">
            <span>{prato.nome}</span>
          </div>
          <p className="descricao">{prato.descricao}</p>
        </div>
        <p className="preço"> {toCurrency(prato.preco)}</p>
      </div>
      <div>
        <img
          className="fotoPadrao"
          src={prato.imagem}
          alt="foto prato"
          width="125px"
          height="105px"
          onClick={handleClick}
          onError={e => {
            e.target.onerror = null;
            e.target.src =
              'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
          }}
        />
        {showModal && (
          <div className="telaTransparente" onClick={() => setShowModal(false)}>  
            <div className="modal">
              <div className="modal-content">
                <img src={prato.imagem} alt="foto ampliada" />
                <button>FECHAR</button> 
              </div>
            </div>
          </div>  
        )}
        <h3 className="subTitulo">{prato.dia}</h3>
      </div>
    </li>
  );
};

export default CardPratos;
