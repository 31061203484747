import React, { useEffect, useState } from 'react';
import './BebidasQuentes.css';
import CardPratos from './../CardPratos/CardPratos';
import { getFirestore, getDocs, collection } from 'firebase/firestore';
import { fireBaseApp } from './../../App';

const BebidasQuentes = () => {
  const [pratos, setPratos] = useState([]);

  const db = getFirestore(fireBaseApp);
  const cardapioRef = collection(db, 'BebidasQuentes');

  const getPratos = async () => {
    const data = await getDocs(cardapioRef);
    const bebidasQuentes = data.docs.map(doc => doc.data());
    setPratos(bebidasQuentes);
  };

  useEffect(() => {
    getPratos();
  }, []);

  return (
    <section id="bebidasQuentes">
      <h2>BEBIDAS QUENTES</h2>

      <div className="cafeLeite">
        <div>
          <div className="espacamento">
            <span>Café com Leite</span>
          </div>
          <div className="posicaoPreco">
            <p className="preço">R$ 4,50</p>
          </div>
        </div>
        <img
          className="fotoCafe"
          src="https://firebasestorage.googleapis.com/v0/b/guara-burguer.appspot.com/o/Bebidas%20quentes%2FFotos%20Guarar%20Burguer%20(166)%20Caf%C3%A9%20com%20leite.jpg?alt=media&token=1f5afbd8-0a44-4186-a5cb-c9cec76bcf9e"
          alt="fotoCafe"
          width="125px"
          height="105px"
        />
      </div>

      <ul>
        {pratos.map((prato, index) => (
          <CardPratos prato={prato} key={`@bebidasQuentes-${index}`} />
        ))}
      </ul>
    </section>
  );
};

export default BebidasQuentes;
