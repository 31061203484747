import React from 'react';
import "./HorarioAlmoco.css"

const HorarioAlmoco = () => {
  return (
    <section id="horarioAlmoço">
      <h1>HORÁRIO ALMOÇO</h1>
      <p>Segunda a Sábado</p>
      <p>(11hrs ás 17hrs)</p>
    </section>
  );
};

export default HorarioAlmoco;
