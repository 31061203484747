import React, { useEffect, useState } from 'react';
import './Sobremesas.css';
import CardPratos from './../CardPratos/CardPratos';
import { getFirestore, getDocs, collection } from 'firebase/firestore';
import { fireBaseApp } from './../../App';

const Sobremesas = () => {
  const [pratos, setPratos] = useState([]);

  const db = getFirestore(fireBaseApp);
  const cardapioRef = collection(db, 'Sobremesas');

  const getPratos = async () => {
    const data = await getDocs(cardapioRef);
    const sobremesas = data.docs.map(doc => doc.data());
    setPratos(sobremesas);
  };

  useEffect(() => {
    getPratos();
  }, []);

  return (
    <section id="sobremesas">
      <h2>SOBREMESAS</h2>
      <ul>
        {pratos.map((prato, index) => (
          <CardPratos prato={prato} key={`@sobremesas-${index}`}/>
        ))}
      </ul>
    </section>
  );
};

export default Sobremesas;
