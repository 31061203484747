/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './Janta.css';
import CardPratos from './../CardPratos/CardPratos';
import { getFirestore, getDocs, collection } from 'firebase/firestore';
import { fireBaseApp } from './../../App';

const Janta = () => {
  const [pratos, setPratos] = useState([]);

  const db = getFirestore(fireBaseApp);
  const cardapioRef = collection(db, 'Janta');

  const getPratos = async () => {
    const data = await getDocs(cardapioRef);
    const janta = data.docs.map(doc => doc.data());
    setPratos(janta);
  };

  useEffect(() => {
    getPratos();
  }, []);

  return (
    <section id="janta">
      <h2>JANTA</h2>
      <h1>Macaxeira - Inhame - Cuscuz</h1>
      <div className="pratosBasicos">
        <h1>Pratos Básicos</h1>
        <p>Galinha Guisada ou Assada - Fígado - Almondega</p>
        <div className="valores">
          <h1>Meio Prato - R$ 10,00</h1>
          <h1>Prato Completo - R$ 15,00</h1>
        </div>
      </div>
      <div className="pratosPremium">
        <h1>Pratos Premium</h1>
        <p>Peito de Frango - Strogonoff - Calabresa</p>
        <div className="valores">
          <h1>Meio Prato - R$ 12,00</h1>
          <h1>Prato Completo - R$ 18,00</h1>
        </div>
      </div>
      <ul>
        {pratos.map((prato, index) => (
          <CardPratos prato={prato} key={`@janta-${index}`} />
        ))}
      </ul>
    </section>
  );
};

export default Janta;
