import './App.css';
import Menu from './components/Menu/Menu';
import PratoDoDia from './components/PratosDoDia/PratoDoDia';
import PratosPrincipais from './components/PratosPrincipais/PratosPrincipais';
import HorarioAlmoco from './components/HorarioAlmoco/HorarioAlmoco';
import TodosOsDias from './components/TodosOsDias/TodosOsDias';
import Janta from './components/Janta/Janta';
import Bebidas from './components/Bebidas/Bebidas';
import Sobremesas from './components/Sobremesas/Sobremesas';
import Porcoes from './components/Porcoes/Porcoes';
import BebidasQuentes from './components/BebidasQuentes/BebidasQuentes';
import Vitaminas from './components/Vitaminas/Vitaminas';

import { firebaseConfig } from './secrets/fireBase';
import { initializeApp } from 'firebase/app';

export const fireBaseApp = initializeApp(firebaseConfig);

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          class="logo"
          src="https://firebasestorage.googleapis.com/v0/b/guara-burguer.appspot.com/o/Logo%2FLogoGB.png?alt=media&token=6fd944a4-9d56-429a-8852-d21a2cd33db1"
          alt="LogoGB"
          width="250px"
        />
        <h1>Guara Burguer</h1>
        <h5>Almoço, lanches e jantas</h5>
      </header>

      <main>
        <Menu />

        <HorarioAlmoco />

        <PratosPrincipais />

        <PratoDoDia />

        <TodosOsDias />

        <Porcoes />

        <Bebidas />

        <Sobremesas />

        <Janta />

        <BebidasQuentes />

        <Vitaminas />
      </main>

      <footer>
        <p>2022 Guara Burguer - Todos os direitos reservados<br/>
            guaraburguer5@gmail.com (81)9.8518.8955</p>
      </footer>
    </div>
  );
}

export default App;
