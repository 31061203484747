/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './PratoDoDia.css';
import { weekDayList, collectionsName } from '../../data/diasDaSemana';
import CardPratos from './../CardPratos/CardPratos';
import { getFirestore, getDocs, collection } from 'firebase/firestore';
import { fireBaseApp } from './../../App';

const PratoDoDia = () => {
  const [pratos, setPratos] = useState([]);

  const weekDay = weekDayList[new Date().getDay()];
  const collectionDay = collectionsName[new Date().getDay()];

  const db = getFirestore(fireBaseApp);
  const cardapioRef = collection(db, collectionDay);

  const getPratos = async () => {
    const data = await getDocs(cardapioRef);
    const pratosDoDia = data.docs.map(doc => doc.data());
    setPratos(pratosDoDia);
  };

  useEffect(() => {
    getPratos();
  }, []);

  return (
    <section id="pratosDoDia">
      <h2>PRATOS DO DIA</h2>

      <div>
        <h3>{weekDay}</h3>
        <p>(Feijão do dia ou Macassar)</p>
        <ul>
          {pratos.map((prato, index) => (
            <CardPratos prato={prato} key={`@pratoDoDia-${index}`} />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default PratoDoDia;
