import React from 'react';
import './Menu.css';

const Menu = () => {
  return (
    <div className="menu-wrapper">
      <div className="menu">
        <nav>
          <ul>
            <li>
              <a href="#pratosPrincipais">PRATOS PRINCIPAIS</a>
            </li>
            <li>
              <a href="#pratosDoDia">PRATOS DO DIA</a>
            </li>
            <li>
              <a href="#todosOsDias">TODOS OS DIAS</a>
            </li>
            <li>
              <a href="#porcoes">PORÇÕES EXTRAS</a>
            </li>
            <li>
              <a href="#bebidas">BEBIDAS</a>
            </li>
            <li>
              <a href="#sobremesas">SOBREMESAS</a>
            </li>
            <li>
              <a href="#janta">JANTA</a>
            </li>
            <li>
              <a href="#bebidasQuentes">BEBIDAS QUENTES</a>
            </li>
            <li>
              <a href="#vitaminas">VITAMINAS</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Menu;
